// 
// general.scss
// Theme general elements and helper styling


// plyr and GLightbox theme
.plyr__control--overlaid, 
.plyr--audio .plyr__control.plyr__tab-focus, 
.plyr--audio .plyr__control:hover, 
.plyr--audio .plyr__control[aria-expanded=true],
.plyr--video .plyr__control.plyr__tab-focus, 
.plyr--video .plyr__control:hover, 
.plyr--video .plyr__control[aria-expanded=true],
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before{
  background: $primary;
}
.plyr--full-ui input[type=range]{
  color: $primary;
}


/* rtl:raw:
.glightbox-clean .gclose, .glightbox-modern .gclose{
  right: auto !important;
  left: 20px;
}
*/

// Search
.nav-search .dropdown-menu{
  top: 120%;
  visibility: hidden;
  opacity: 0;
  display: block;
  transition: $transition-base;
  width: 300px;
  left: auto;
  right: 0;
}
.nav-search .dropdown-menu.show{
  top: 100%;
  visibility: visible;
  opacity: 1;
}
@include media-breakpoint-down(sm) {
  .nav-search .dropdown-menu{
    width: 100%;
  }
}

// Back to top
.back-top {
  cursor: pointer;
  position: fixed !important;
  bottom: 40px;
  right: 40px;
  display: block;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transform: translateY(50%);
  transition: $transition-base;
  background: rgba($primary, $soft-alpha + 0.1);
  color: $primary;
  border-radius: 10%;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  i{
    font-size: 1.6rem;
    vertical-align: middle;
  }
  &:hover{
    background: $primary;
    color: $white;
  }
  &.back-top-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
}
@include media-breakpoint-down(md) {
  .back-top {
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    i{
      font-size: 1rem;
    }
  }
}

// To flip element in RTL
/* rtl:raw:
  .rtl-flip {
    transform: scaleX(-1);
  }
  .rtl-flip.bi:before{
    transform: inherit;
  }
*/

// Ribbon css
.ribbon{
  position: absolute;
  top: 15px;
  left: 0;
  background: $white;
  padding: 0 10px;
  color:$dark;
  box-shadow: 0 0 40px rgba(0, 0, 0, .15);
}
.ribbon::before{
  content: "";
  position: absolute;
  top: 0px;
  right: -20px;
  border-top: 0.746em solid #fff;
  border-bottom: 0.746em solid #fff;
  border-right: 20px solid transparent;
  border-left: 0px solid transparent;
}

//Upload remove button
.uploadremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 0;
  padding: 0;
  background: #dc3545;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transition: $transition-base;
}

// Sticky element
.sticky-element {
  position: fixed;
  bottom: 50px;
  left: 50px;
  width: 300px;
  visibility: hidden;
  z-index: 1000;
  opacity: 0;
  transform: translateX(-50%);
  transition: $transition-base;
}

.sticky-element.sticky-element-sticked {
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);
}

// Quick fix for bootstrap font width
.bi.fa-fw{
  display: inline-block;
}

// pre loader
.preloader {
  background-color: $white;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .preloader-item {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}


// Radio button css for soft success color
.btn-success-soft-check{
  background-color: $light;
  text-align: start;
}

.btn-success-soft-check:hover {
  background-color: rgba($success, 0.2);
  border: $border-width solid $success;
}
.btn-check:active+.btn-success-soft-check, .btn-check:checked+.btn-success-soft-check, .btn-success-soft-check.active, .btn-success-soft-check:active, .show>.btn-success-soft-check.dropdown-toggle {
  background-color: rgba($success, 0.2);
  color: none;
  border: $border-width solid $success !important;
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}

// Radio button css for success color
.btn-primary-check{
  border:$border-width solid $secondary;
}

.btn-primary-check:hover {
  background-color:$primary;
  color: $white;
  border:$border-width solid $primary;
}
.btn-check:active+.btn-primary-check, .btn-check:checked+.btn-primary-check, .btn-primary-check.active, .btn-primary-check:active, .show>.btn-primary-check.dropdown-toggle {
  background-color:$primary;
  color: $white;
  border:$border-width solid $primary;
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}








////////////////////////////////////////////
// Card blogzine

.plyr--audio .plyr__controls {
  background: transparent;
}

// sticky post (visible on scroll)
.sticky-post{
  position: fixed;
  bottom: 100px;
  right: 50px;
  width: 300px;
  visibility: hidden;
  opacity: 0;
  transform: translateX(50%);
  transition: $transition-base;
}
.sticky-post.sticky-post-sticked{
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);

}

// parallax
.bg-parallax{
  transform: translate3d(0, 0, 0);
  &:before {
    z-index: 0;
  }
}

//  Footer fixed 
@include media-breakpoint-up(md) {
  footer.footer-sticky {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1 !important;
  }
}


// Dark mode item hide and show
.dark-mode-item{
  display: none !important;
}

html[data-theme='dark']{
  .dark-mode-item{
    display: block !important;
  }
  .light-mode-item{
    display: none !important;
  }
}

// Dark mode switch
.dark-mode-switch{
  margin: 0;
  &.form-check{
    min-height: auto;
  }
  .form-check-input, .form-check-label{
    cursor: pointer !important;
  }
}


.modeswitch {
  width: 42px;
  height: 12px;
  background: $gray-600;
  border-radius: 60px;
  display: flex;
  align-items: center;
  margin: 0 16px;
  transition: all 1s;
  &:hover {
    cursor: pointer;
  }
  .switch {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-14%);
    height: 26px;
    width: 26px;
    background: #d8831b;
    border: 2px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
    font-size: 14px;
    color: $white;
    transition: all 1s;

    &:before {
      content: "\f185";
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      border: none;
      height: 26px;
      width: 26px;
      line-height: 26px;
      text-align: center;
      display: block;
    }
  }
}

html[data-theme='dark'] .modeswitch {
  .switch {
    transform: translateX(18px) rotate(1turn);
    background: $dark;
    &:before {
      content: "\f186";
    }
  }
  background: $gray-400;
}
.navbar-dark{
  .modeswitch{
    background: $gray-500;
  }
}

// modeswitch wrap 2
.modeswitch-small {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .switch {
    .switch-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 26px;
      font-size: 22px;
      color: $warning;
      transition: all 0.3s;
      &:before {
        content: "\f185";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        border: none;
        height: 26px;
        width: 26px;
        line-height: 26px;
        text-align: center;
        display: block;
      }
    }
  }
}
html[data-theme=dark] .modeswitch-small .switch-icon:before {
  content: "\f186";
  color: $white;
}

// Font size accessibility
html{
  &.font-lg{
    font-size:20px;
    transition: $transition-base;
    @include media-breakpoint-up(xxl) {
      .container{
        max-width: 1300px;
        transition: $transition-base;
      }
    }
  }
  &.font-sm{
    font-size:14px;
    transition: $transition-base;
  }
} 

// Rotating text badge
.rotating-text-badge { 
  position: absolute; 
  right: -330px;
  top: -100px; 
  width: 1000px; 
  height: 1000px; 
  overflow: hidden; 
}

/* rtl:raw:
  .rotating-text-badge { 
    direction: ltr !important;
  }
*/

.rotating-text-badge text { 
  font-size: 20px; 
  letter-spacing: 2px;
}
.rotating-text-badge svg {
  width: 1000px;
  height: 1000px;
  animation-name: rotate;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from { -webkit-transform: rotate(360deg); }
    to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
    from { -moz-transform: rotate(360deg); }
    to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
    from { -ms-transform: rotate(360deg); }
    to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
    from { -o-transform: rotate(360deg); }
    to { -o-transform: rotate(0); }
}
@keyframes rotate {
    from { transform: rotate(360deg); }
    to { transform: rotate(0); }
}

// Spilt fixed
.spilt-fixed {
  position: fixed;
  left: 0;
  margin-left: 90px;
  width: 600px;
}

// Spilt content
.spilt-content {
  padding-left: 740px;
  padding-right: 3rem;
}

// Media down xl
@include media-breakpoint-down(xl) { 
  .spilt-fixed {
    width: 400px;
  }
  .spilt-content { 
    padding-left: 540px;
  }
  .rotating-text-badge {
    right: -230px;
    top: 0;
    width: 740px;
    height: 740px;
  }
}

// Media down lg
@include media-breakpoint-down(lg) { 
  .spilt-fixed { 
    margin-left: 0;
    position: static;
    width: 100%;
    height: auto !important;
  }
  .spilt-content {
    padding-left: 105px;
    padding-right: 0;
  }
 }

 // Media down sm
 @include media-breakpoint-down(sm) { 
  .navbar-vertical-compact {
    width: 70px;
  }
  .spilt-content {
    padding-left: 70px;
  }
}

// Overlay scrollbar color
.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle, 
.os-theme-dark>.os-scrollbar:hover>.os-scrollbar-track>.os-scrollbar-handle {
  background: rgba($black, 0.02);
}


/* rtl:raw:
  .editor-container {
    direction: rtl;
  }
  .editor-container ol {
    margin: 0 2em;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    left: 0;
    right: inherit;
  }
  .ql-editor {
    text-align: right;
  }
  .ql-snow .ql-editor blockquote {
    border-right: 4px solid #ccc;
    border-left: 0;
    padding-right: 16px;
  }

  .ql-editor ol li:not(.ql-direction-rtl), .ql-editor ul li:not(.ql-direction-rtl) {
    padding-right: 1.5em;
  }

  .ql-editor li:not(.ql-direction-rtl)::before {
    margin-right: -1.5em;
    margin-left: 0.3em;
    text-align: left;
  }
*/




////////////////////////////////////////////
// Card social

// Bg white and dark according to theme mode
.bg-mode{
  background: $mode;
}

.text-mode{
  color: $dark-gray;
}


// Filter
.grayscale{
  filter: grayscale(100%);
  transition: all .6s ease;
  &:hover {
    filter: none;
    -webkit-filter: grayscale(0%);
  }
}

// Fixed width icon
.fw-icon{
  text-align: center;
  width: 1.25em;
  display: inline-block;
}
.icon-xs{
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.6em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-sm{
  height: 2rem;
  width: 2rem;
  font-size: 0.7em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-md {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.9em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-lg{
  height: 3rem;
  width: 3rem;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-xl{
  height: 4rem;
  width: 4rem;
  font-size: 1.6em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.icon-xxl{
  height: 5.125rem;
  width: 5.125rem;
  font-size: 2em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

// password strength meter
.password-strength-meter{
  background-color: $input-group-addon-bg !important;
}

.glightbox-clean .gslide-description {
  background: $mode;
}

// Plyr controls none
.plyr__controls-none{
  .plyr__controls {
    display: none;
  }
  .plyr--video {
    border-radius: 20px;
  }
} 

// nested comment

.comment-wrap {
  .comment-item {
    position: relative;
    .avatar {
      position: relative;
      z-index: 1;
    }
    &:last-child {
			.comment-line-inner {
				background: $light;
				height: 20px;
			}
		}
  }
  .comment-item-nested {
    padding-left: 2.75rem;
  }
	& > .comment-item {
		.comment-line-inner {
			position: absolute;
			left: 16px;
			top: 0;
			width: 2px;
			background-color: $light;
			height: 100%;
		}
	}
}
.comment-item-nested {
	.comment-item {
    position: relative;
		.comment-line-inner {
			position: absolute;
			left: -28px;
			top: 0;
			width: 2px;
			background-color: $light;
			height: 100%;
			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 20px;
				height: 2px;
				width: 26px;
				background: $light;
			}
		}
	}
}

// Rounded Helper
@include media-breakpoint-up(lg) {
  .rounded-end-lg-0 {
    border-top-right-radius:0;
    border-bottom-right-radius:0;
  }
  .rounded-start-lg-0{
    border-top-left-radius:0;
    border-bottom-left-radius:0;
  }
  .border-end-lg-0{
    border-right: 0;
  }
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

// flip-horizontal
.flip-horizontal {
  transform: scaleX(-1);
  display: inline-block;
}

// Timeline
.timeline{
  width: 100%;
  .timeline-item{
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
    position: relative;
    &:last-child{
      margin-bottom: 0;
    }
    .timeline-icon{
      margin-right: 0.8rem;
      position: relative;
      position: relative;
    }
    .timeline-content{
      width: 100%;
    }
    &:before{
      content: "";
      position: absolute;
      width: 2px;
      height: calc(100% - 1rem);
      background-color: $border-color;
      top: 2.5rem;
      left: 1.5rem;
      transform: translatex(-50%);
    }
    &:last-child:before {
      height: calc(100% - 2.5rem);
    }
  }
}

// Card chat height calculation
@include media-breakpoint-up(lg) {
  .card-chat {
    height: calc(100vh - 5.1125rem - 1.5rem);
  }
  .card-chat-list {
    height: calc(100vh - 10.112rem - 1.5rem);
  }
  .chat-conversation-content {
    height: calc(100% - 5.25rem);
  }
  .chat-tab-list {
    height: calc(100% - 0.75rem);
  }
 }

// Typing Dot animation for chat 
.typing {
	.dot {
		animation: TypingAnimation 1.8s infinite ease-in-out;
		background-color: rgba($secondary, 0.7);
		border-radius: 50%;
		height: 7px;
		margin-right: 4px;
		vertical-align: middle;
		width: 7px;
		display: inline-block;
		&:nth-child(1) {
			animation-delay: 200ms;
		}
		&:nth-child(2) {
			animation-delay: 300ms;
		}
		&:nth-child(3) {
			animation-delay: 400ms;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

@keyframes TypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:rgba($secondary, 0.7); 
  }
  28% {
    transform: translateY(-7px);
    background-color: rgba($secondary, 0.4);
  }
  44% {
    transform: translateY(0px);
    background-color: rgba($secondary, 0.2);
  }
}

// bootstrap icon line height
// .bi::before,
// [class^="bi-"]::before,
// [class*=" bi-"]::before { 
//   line-height: 1.5;
// }

// Fake password icon 
.fakepasswordicon { 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fakepasswordicon.fa-eye:before {
  content: "\f06e" !important;
}

// ticket
.ticket-border {
  border-left: 2px dashed $secondary;
  padding-left: 50px;
  position: relative;
  &:before {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $mode;
    content: "";
    top: -50px;
    left: -25px;
    position: absolute;
  }
  &:after {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $mode;
    content: "";
    bottom: -50px;
    left: -25px;
    position: absolute;
  }
}
@include media-breakpoint-down(sm) { 
  .ticket-border {
    padding-left: 0;
    border: 0;
    &:after, &:before { 
      display: none;
    }
  }
}

// .plyr--audio .plyr__controls
.plyr--audio .plyr__controls {
  background: transparent;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: $primary;
}

// Disable textarea resizing
[data-autoresize]{
  resize: none;
}

//////////////
/// Folio

// image trail effects
.content__img {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
  opacity: 0;
}

// ityped cursor blink
.ityped-cursor {
  color: $dark;
  opacity: 1;
  animation: cursorblink 0.3s infinite;
  animation-direction: alternate;
}

@keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

// Search
.search-full {
  height: 0px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1080;
}

.search-full.show {
  height: 100vh;
}

//  Scroll Down 
.scroll-down {
  width: 25px;
  right: 0px;
  position: absolute;
  bottom: 0;
  color: $gray-800;
  z-index: 2;

  &.scroll-down-light {
    color: $white;

    .scroll-line {
      &:after {
        background: linear-gradient(180deg, $white 50%, rgba(21, 175, 90, 0) 0);
        background-position: 0 -60px;
        background-size: 100% 200%;
      }

    }

  }

  .scoll-text {
    writing-mode: vertical-rl;
    margin-top: 8px;
  }

  .scroll-line {
    width: 1px;
    height: 60px;
    overflow: hidden;
    text-align: center;
    margin: 0 auto;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: linear-gradient(180deg, $gray-800 50%, rgba(21, 175, 90, 0) 0);
      background-position: 0 -60px;
      background-size: 100% 200%;
      -webkit-animation: scrolldown 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
      animation: scrolldown 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
    }

  }

}

@-webkit-keyframes scrolldown {
  0% {
    background-position: 0 -60px
  }

  75% {
    background-position: 0 0
  }

  to {
    background-position: 0 60px
  }

}

@keyframes scrolldown {
  0% {
    background-position: 0 -60px
  }

  75% {
    background-position: 0 0
  }

  to {
    background-position: 0 60px
  }

}

//
// Animations
// 

// marquee animation
.marquee-animation {
  animation: marqueeAnimation 20s linear infinite;
  position: absolute;
  display: block;
  z-index: 10;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  white-space: nowrap;
}

.marquee-animation.hover {
  animation-play-state: paused;
}

@keyframes marqueeAnimation {
  0% {
    transform: translateX(-2%)
  }

  100% {
    transform: translateX(-100%)
  }

}

// tilt animation (Used with tilt.js)
.tilt-animation {
  transform-style: preserve-3d;
}

// rotate infinite animation
.rotate-infinite {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }

}

// line draw animation
.line-draw-animation {
  position: relative;

  &::before {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: $primary;
    right: 0;
    top: 0;
    transition-delay: 0.2s;
  }

  &::after {
    content: "";
    width: 0;
    height: 2px;
    position: absolute;
    transition: all 0.2s linear;
    background: $primary;
    left: 0;
    bottom: 0;
    transition-delay: 0.6s;
  }

  &:hover {
    &::before {
      width: 100%;
      transition-delay: 0.4s;
    }

    &::after {
      width: 100%;
      transition-delay: 0s;
    }

    .line-draw-inner {
      &::before {
        height: 100%;
        transition-delay: 0.6s;
      }

      &::after {
        height: 100%;
        transition-delay: 0.2s;
      }

    }

  }

  .line-draw-inner {
    &::after {
      transition-delay: 0.4s;
      right: 0;
      bottom: 0;
    }

    &::before {
      transition-delay: 0s;
      left: 0;
      top: 0;
    }

  }

}

.line-draw-inner {
  &::before {
    content: "";
    width: 2px;
    height: 0;
    position: absolute;
    transition: all 0.2s linear;
    background: $primary;
  }

  &::after {
    content: "";
    width: 2px;
    height: 0;
    position: absolute;
    transition: all 0.2s linear;
    background: $primary;
  }

}

// Check mark animation
.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $success;
  fill: none;
  animation: checkmarkstroke 1.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  box-shadow: inset 0px 0px 0px $success;
  animation: checkmarkfill .6s ease-in-out .9s forwards, checkmarkscale .8s ease-in-out 2s both;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: checkmarkstroke 1.1s cubic-bezier(0.65, 0, 0.45, 1) 1.2s forwards;
}

@keyframes checkmarkstroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes checkmarkscale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.3, 1.3, 1);
  }
}
@keyframes checkmarkfill {
  100% {
    box-shadow: inset 0px 0px 0px 60px $success;
  }
}

// AOS Reveal animation
.reveal-item {
  position: relative;
  display: inline-block;
  overflow: hidden;

  .reveal-animation {
    position: absolute;
    top: 0;
    width: 100%;
    height: 101%;
    background: $gray-200;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $gray-400;
      transition-property: transform;
      transition-duration: 1s;
    }

  }

}

.reveal-animation.reveal-primary::before {
  background: $primary;
}

.reveal-animation.reveal-dark::before {
  background: $dark;
}

.reveal-animation.reveal-white::before {
  background: $white;
}

.reveal-animation.reveal-top.aos-animate::before,
.reveal-animation.reveal-bottom.aos-animate::before {
  transform: scaleY(1);
}

.reveal-animation.reveal-start.aos-animate::before,
.reveal-animation.reveal-end.aos-animate::before {
  transform: scaleX(1);
}

.reveal-animation.reveal-top::before {
  transform: scaleY(0);
  transform-origin: 0% 100%;
}

.reveal-animation.reveal-start::before {
  transform: scaleX(0);
  transform-origin: 100% 0%;
}

.reveal-animation.reveal-end::before {
  transform: scaleX(0);
  transform-origin: 0% 100%;
}

.reveal-animation.reveal-bottom::before {
  transform: scaleY(0);
  transform-origin: 100% 0%;
}

//  AOS animate for reveal animation
[data-aos="reveal-top"],
[data-aos="reveal-start"],
[data-aos="reveal-end"],
[data-aos="reveal-bottom"] {
  transition-property: transform;
  transition-delay: 1s;
}

[data-aos="reveal-top"] {
  transform: scaleY(1);

  &.aos-animate {
    transform: scaleY(0);
    transform-origin: 100% 0%;
  }

}

[data-aos="reveal-start"] {
  transform: scaleX(1);

  &.aos-animate {
    transform: scaleX(0);
    transform-origin: 0% 100%;
  }

}

[data-aos="reveal-end"] {
  transform: scaleX(1);

  &.aos-animate {
    transform: scaleX(0);
    transform-origin: 100% 0%;
  }

}

[data-aos="reveal-bottom"] {
  transform: scaleY(1);

  &.aos-animate {
    transform: scaleY(0);
    transform-origin: 0% 100%;
  }

}

[data-aos="reveal-item"] {
  visibility: hidden;
  transition-property: visibility;
  transition-duration: 0s;

  &.aos-animate {
    visibility: visible;
  }

}

// col md full right
@include media-breakpoint-up(md) {
  .col-md-full-right {
    position: absolute;
    width: 1000%;
    max-width: 50%;
    height: 100%;
    right: 0;
    bottom: 0;
  }
}

// featured label
.featured-label {
  position: absolute;
  z-index: 99;
  background: $primary;
  color: $white;
  transform: rotate(45deg);
  text-align: center;
  top: -8px;
  right: -48px;
  width: 120px;
  padding: 16px 0 3px 0;
}
