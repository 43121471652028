// 
// buttons.scss
// Extended from Bootstrap
// 

// 
// Additional styles for theme
// 

.btn {
  letter-spacing: 0.5px;
  // position: relative;
  overflow: hidden;
  outline: 0;
  white-space: nowrap;
  margin-bottom: 6px;
}
.input-group .btn{
  margin-bottom: 0;
}
.btn-xs{
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: $btn-border-radius;
}

// button light
.btn-light {
  background: shade-color($light, 10%);
  border-color: shade-color($light, 10%);
  &.active, &:active, &:focus, &:hover {
    background: shade-color($light, 20%);
    border-color: shade-color($light, 20%);
  }
}

// button white
.btn-white {
  background: $white;
  color: $gray-800;
  &.active, &:active, &:focus, &:hover {
    background: $gray-200;
    color: $gray-800;
  }
}
.btn-outline-white {
  background: transparent;
  border-color: $white;
  color: $white;
  &.active, &:active, &:focus, &:hover {
    color: $gray-800;
    background: $white;
  }
}
.btn-outline-light{
  background: transparent;
  border-color: $gray-400;
  color: $body-color;
	&:active, &:hover, &:focus{
		background: $gray-400;
		border-color: $gray-400;
		color: $gray-800;
	}
}
.btn-check:checked + .btn-outline-light
{
  background: $gray-400;
  border-color: $gray-400;
  color: $gray-800;
}

// button round
.btn-round{
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
  i{
    margin:0; 
  }
  // Quick fix for play button centered
  .fa-play{
    padding-left: 3px;
  }
  &.btn-lg{
    height: 55px;
    width: 55px;
    line-height: 55px;
  }
  &.btn-sm{
    height: 32px;
    width: 32px;
    line-height: 30px;
    i{
      font-size: .6rem;
    }
  }
}

// Quick fix for play button centered in RTL
/*rtl:raw:
.btn-round .fa-play{
  padding-left: 3px;
  padding-right: 0;
}
*/

// button soft
@mixin button-soft-variant($color, $soft-background: rgba($color, .1)) {
  color: $color;
  background-color: $soft-background;
  &:hover {
    color: color-contrast($color);
    background-color: $color;
  }
}
@each $color, $value in $theme-colors {
  .btn-#{$color}-soft {
    @include button-soft-variant($value);
  }
}

// Button shadow
@mixin btn-shadow($color,
  $background:($color), 
  $box-shadow: 0 0 0 8px rgba($color, .4),
  $hover-background: if($color == $color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-color: color-contrast($hover-background)
) {
  color:$white;
  box-shadow: $box-shadow;
  background-color: $background;
  &:hover {
    color: $hover-color;
  }
  .btn-check:focus + &,
  &:focus {
    box-shadow: $box-shadow;
  }
}
@each $color, $value in $theme-colors {
  .btn-#{$color}-shadow {
    transition: $transition-base;
      @include btn-shadow($value);
  }
}

// Button primary soft check hover
.btn-primary-soft-check:hover {
  background-color: rgba($primary, 0.2);
  border-color: rgba($primary, 0.0);
}
.btn-check:active+.btn-primary-soft-check, .btn-check:checked+.btn-primary-soft-check, .btn-primary-soft-check.active, .btn-primary-soft-check:active, .show>.btn-primary-soft-check.dropdown-toggle {
  background-color: rgba($primary, 0.2);
  color: $primary;
  border-color: rgba($primary, 0.0);
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}

// Advance filter see more
.btn-more i{
  transition: $transition-base;
}
.btn-more[aria-expanded="true"] i{
  transform: rotate(180deg);
  transition: $transition-base;
  transform-origin: center;

}
.btn-more .see-more{
  display:block;
}
.btn-more .see-less{
  display:none;
}
.btn-more[aria-expanded="true"] .see-more{
  display:none;
}
.btn-more[aria-expanded="true"] .see-less{
  display:block;
}

//Hover transition
.btn-transition {
  transition: all .2s ease-in-out;
}
.btn-transition:focus, .btn-transition:hover {
  -webkit-transform: translateY(-.1875rem);
  transform: translateY(-.1875rem);
}






//////////////////////
/// Blogzine

.btn-link.btn-link-border{
  padding: 0px;
  background: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 6%;
  background-repeat: no-repeat;
  background-position: left 100%;
  transition-duration: .5s;
  font-weight: inherit;
  padding: 0;
}
.btn-link.btn-link-border:hover {
  background-size: 100% 6%;
}





//////////////////////
/// Social

// button dashed
.btn-dashed{
  background: 
  linear-gradient(90deg, $border-color 50%, transparent 50%),
  linear-gradient(0deg, $border-color 50%, transparent 50%),
  linear-gradient(90deg, $border-color 50%, transparent 50%),
  linear-gradient(0deg, $border-color 50%, transparent 50%);
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 10px 2px, 2px 10px, 10px 2px, 2px 10px;
  background-position: left top, right top, left bottom, left top;
  padding: $btn-padding-y, $btn-padding-x;
    &:hover{
      animation: border-dance 4s infinite linear;
    }
  }
  @keyframes border-dance{
    0%{
      background-position: left top, right top, right bottom, left bottom;
    }
    100% {
      background-position: right top, right bottom, left bottom, left top;
    }
  }
 
// button loader
.btn-loader{
  position: relative;
  text-align: center;
  .load-icon{
    display: none;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .load-text{
    opacity: 1;
    visibility: visible;
  }
}
.btn-loader.active{
  .load-text{
    opacity: 0;
    visibility: hidden;
  }
  .load-icon{
    display: block;
  }
}

// Spinner dots
.spinner-dots{
  align-items: center;
  display: flex;
  justify-content: center;
  }
  .spinner-dot{
  background-color: $gray-500;
  display: inline-block;
  height: 4px;
  margin: 2px;
  border-radius: 50%;
  width: 4px;
  }
  @keyframes dot{
  0% { background-color: $gray-500; transform: scale(1); }
  50% { background-color: $light-gray; transform: scale(1.3); }
  100% { background-color: $gray-500; transform: scale(1); }
}

// button link loader active
.btn-link-loader.active {
  .spinner-dots { 
    .spinner-dot { 
      animation: dot ease-in-out 1s infinite;
    }
    .spinner-dot:nth-of-type(2) {
      animation-delay: 0.2s;
    }
    .spinner-dot:nth-of-type(3) {
    animation-delay: 0.3s;
    }
  }
}

// button soft hover
@mixin button-soft-variant-hover($color, $soft-background: rgba($color, .1)){
  &:hover {
    color: $color !important;
    background-color: $soft-background;
  }
}
@each $color, $value in $theme-colors{
  .btn-#{$color}-soft-hover:hover{
    @include button-soft-variant-hover($value);
  }
}



////////////////
/// folio

// button line
.btn-line{
  padding: 0.5rem 1rem 0.5rem 2.2rem;
  position: relative;
  &:before {
    content: "";
    width: 16px;
    height: 2px;
    display: block;
    background: $body-color;
    position: absolute;
    left: 15px;
    bottom: 15px; 
    transition: $transition-base;
  }
  &:after {
    content: "";
    width: 16px;
    height: 2px;
    display: block;
    background: $body-color;
    position: absolute;
    right: -16px;
    bottom: 15px;
    transition: $transition-base;
  }
  &:hover{
    padding: 0.5rem 2.2rem 0.5rem 1rem;
    &:before {
      right: auto;
      left: -16px;
    }
    &:after {
      right: 16px;
      left: auto;
      transition: $transition-base;
      animation: blink-animation 1s steps(5, start) infinite;
    }
  }
}

// btn line color variant generator for .btn-#name
@each $name, $color in $theme-colors {
  .btn-line{
    &.btn-#{$name}{
      &:before {
        background: color-contrast($color);
      }
      &:after {
        background: color-contrast($color);
      }
    }
  }
}

.btn-line.btn-white{
  &:before, &:after {
    background: $gray-800;
  }
}
.btn-line.text-white{
  padding: 0.5rem 0rem 0.5rem 1.3rem;
  &:before{
    background: $white;
    left: 0;
  }
  &:after{
    background: $white;
    right: -16px
  }
  &:hover{
    padding: 0.5rem 1.3rem 0.5rem 0rem;
    &:before{
      left: -16px;
    }
    &:after{
      right: 0;
    }
  }
}

// btn line color variant generator for .text-#name
@each $color, $value in $theme-colors {
  .btn-line{
    &.text-#{$color}{
      padding: 0.5rem 0rem 0.5rem 1.3rem;
      &:before{
        background: $value;
        left: 0;
      }
      &:after{
        background: $value;
        right: -16px
      }
      &:hover{
        padding: 0.5rem 1.3rem 0.5rem 0rem;
        &:before{
          left: -16px;
        }
        &:after{
          right: 0;
        }
      }
    }
  }
}

.btn-line.btn-sm{
  &:before {
    bottom: 12px;
  }
  &:after {
    bottom: 12px;
  }
}

// btn line border animation
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

// btn ripple
.btn-ripple {
  position: relative;
  overflow: visible;
}

.btn-ripple:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-color: inherit;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 1;
  animation-name: rippleanim;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.4, 0, .3, 1);
  animation-delay: 1s;
}

@keyframes rippleanim {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba($primary, 0.90);
  }

  100% {
    opacity: 0;
    box-shadow: 0 0 0 15px rgba($primary, 0.40);
  }
}

@-webkit-keyframes rippleanim {
  0% {
    opacity: 1;
    background: rgba($primary, 0.90);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    background: rgba($primary, 0.40);
    transform: scale(1.5);
  }
}