// 
// utilities.scss
// Theme component for utility classes (helper classes)


// text color on hover
.text-primary-hover {
  a {
    color: $gray-400;
    &:hover {
      color: $primary !important;
    }
  }
}

a.text-primary-hover, button.text-primary-hover {
  color: $gray-600;
  &:hover {
    color: $primary !important;
  }
}

.text-primary-hover {
  li {
    color: $gray-400;
    &:hover {
      color: $primary !important;
    }
  }
}

// font family
.font-base {
  font-family: $font-family-base !important;
}

.font-heading {
  font-family: $font-family-heading !important;
}

// Filter
.grayscale {
  filter: grayscale(100%);
  transition: all .6s ease;
  &:hover {
    filter: none;
    -webkit-filter: grayscale(0%);
  }
}

// On hover
.zoom-hover {
  transform: scale(1);
  &:hover {
    transform: scale(1.2);
  }
}

// Icons fixed height and width
.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 75%;
}
.icon-md {
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
  text-align: center;
  font-size: .8rem;
}
.icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  font-size: 1.2rem;
}
.icon-xl {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  font-size: 1.9rem;
}

.icon-xxl {
  width: 7rem;
  height: 7rem;
  line-height: 7rem;
  text-align: center;
  font-size: 2.5rem;
}

// Card hover shadow
.shadow-hover:hover{
  transition: all .3s ease-in-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, .25);
}



////////////////////////////////////////////
// blogzine

.up-hover {
  transition: $transition-base;
  &:hover {
    transform: translateY(-10px);
  }
}

// oversized block for force outside of col width
@include media-breakpoint-up(lg) {
  .oversized-block{
    width: calc(100% + 140px - 20px + 140px - 20px);
    margin-left: calc(-140px + 20px);
  }
}

////////////////////////////////////////////
// Folio

.mt-30 {
  margin-top: $grid-gutter-width;
}
.mb-30 {
  margin-bottom: $grid-gutter-width;
}


@include media-breakpoint-up(lg){
  .position-lg-absolute{
    position: absolute;
  }
}

// background multiply color
.bg-primary-multiply {
  background-color: $primary;
  mix-blend-mode: multiply;
  img{
    mix-blend-mode: multiply;
  }
}
.bg-primary-multiply-hover {
  background-color: $primary;
  transition: $transition-base;
  &:hover img{
    mix-blend-mode: multiply;
    transition: $transition-base;
  }
}

// Bg color in force full width left or right
.bg-primary-start:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: $primary;
  z-index: -1;
}
.bg-white-start:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: $white;
  z-index: -1;
}
.bg-dark-start:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: $dark;
  z-index: -1;
}
.bg-primary-end:before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 0;
  bottom: 0;
  background: $primary;
  z-index: -1;
}
.bg-white-end:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: $white;
  z-index: -1;
}
.bg-dark-end:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: $gray-800;
  z-index: -1;
}

.font-alt {
  font-family: $font-family-alt !important;
}

// Scale (zoom)
.zoom-1{
  transform: scale(1.1);
}
.zoom-2{
  transform: scale(1.3);
}
.zoom-3{
  transform: scale(1.5);
}
.zoom-4{
  transform: scale(2);
}
.zoom-n1{
  transform: scale(0.9);
}
.zoom-n2{
  transform: scale(0.6);
}
.zoom-n3{
  transform: scale(0.4);
}
.zoom-n4{
  transform: scale(0.2);
}

// blur
.blur-1{
  filter: blur(2px);
}

.blur-2{
  filter: blur(5px);
}

.blur-3{
  filter: blur(10px);
}

// rotate
.rotate-10 {
  transform: rotate(-10deg);
}
.rotate-45 {
  transform: rotate(-45deg);
}

// blink animation (Used keyframes animation from line button)
.blink-animation{
  transition: $transition-base;
  animation: blink-animation 1s steps(5, start) infinite;
  visibility: visible;
}

// SVG colors
.fill-none{
  fill: none;
}
.fill-primary-light {
  fill: shift-color($primary, -20%);
}
.fill-primary-dark {
  fill: shift-color($primary, 12%);
}

.stroke-primary {
  stroke: $primary;
}
.stroke-10{
  stroke-miterlimit:10;
}
.svg-custom-border-dark{
  fill:none;
  stroke: $gray-800;
  stroke-width:1.1786;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10;
}
.svg-custom-border-white{
  fill:none;
  stroke: $white;
  stroke-width:1.1786;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10;
}
.svg-custom-border-primary{
  fill:none;
  stroke: $primary;
  stroke-width:1.1786;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10;
}
.svg-custom-border-2{
  fill:none;
  stroke: $dark-gray;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:10;  
}