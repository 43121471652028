// 
// offcanvas.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

.offcanvas-sm {
  width: $offcanvas-horizontal-width-sm;
} 

@include media-breakpoint-down(md) { 
  .offcanvas-start {
    width: 260px;
  }
}

////////////
/// Folio
 
// Quick fix to remove padding while offcanvas is opened. If you remove overflow-x hidden from HTML tag then you should remove below padding
[data-bs-padding-right]{
  padding: 0 !important;
}