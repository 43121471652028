// 
// nav.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
// 

// Pills
.nav-pills {
  .nav-link {
    cursor: pointer;
    margin-bottom: 15px;
    padding: 0.5rem 1rem;
  }
}

// nav
.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;
}

// nav divider
.nav.nav-divider{
  a:not(.dropdown-item){
    color: inherit;
  }
  .nav-item+.nav-item:before{
    content: "\2022";
    color: inherit;
    padding-left: .65rem;
    padding-right: .75rem;
    opacity: 0.8;
  }
  .nav-item{
    display: inline-block;
    vertical-align: middle;
  }
  .nav-link{
    padding: 0;
    color: inherit;
  }
}

//Nav pill primary soft
.nav-pill-soft .nav-link {
  background-color: rgba($primary, 0.1);
  color: $primary;
  border: none;
}
.nav-pill-soft .nav-link.active, .nav-pill-soft .show > .nav-link {
  color: $white !important;
  background-color: $primary;
}

//Nav pill dark soft
.nav-pill-dark-soft .nav-link {
  background-color: rgba($dark-gray, 0.1);
  color: $dark-gray;
  border: none;
}
.nav-pill-dark-soft .nav-link.active, .nav-pill-dark-soft .show > .nav-link {
  color: $white;
  background-color: $dark-gray;
}

//Nav pill bg soft
.nav-pills-bg-soft{
  background-color: rgba($primary, 0.1);
  padding: 0.625rem 0;
  border-radius: 0.625rem;
}
.nav-pills-bg-soft .nav-link{
  color:$primary;
}

// tab line
.nav-pills.nav-tabs-line { 
  padding: 0.625rem 0;
  .nav-link {
    border: transparent;
    color:$primary
  }
  .nav-link.active {
      color:$primary;
      background-color: rgba($primary, 0.1);
    }
  .nav-item {
    margin-bottom: -2px;
  }
}

//Nav tabs shadow
.nav-tabs-bg-dark{
  border-radius: $border-radius-lg;
  background-color: $dark;
  padding: 1.5rem;
}
.nav-tabs-bg-dark .nav-link {
  // border: $border-width solid $border-color;
  // box-shadow:$box-shadow;
  // background-color:  rgba($white, 0.1);
  color: $white;
  border-radius: $border-radius-lg;
  padding: 0.825rem;
  margin-bottom: 5px;
}
.nav-tabs-bg-dark .nav-link.active, .nav-tabs-bg-dark .show > .nav-link {
  color: $dark !important;
  // border: $border-width solid $primary;
  background-color: $white;
  border-radius: $border-radius;
}










//////////////////////////
/// Blogzine

// Tab
.tab-content {
  padding: 25px 0;
  margin-bottom: 20px;
}

.nav-tabs {
  .nav-link {
    padding: 0.5rem 1rem;
    border: none;
  }
  .nav-link.active {
    background: $primary;
    color: $white;
    border: none;
    .nav-link {
      color: $white;
    }
  }
}

// tab line
.nav-tabs.nav-tabs-line { 
  border-bottom: solid 3px $border-color;
  .nav-link {
    border: transparent;
  }
  .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .nav-link {
    background: transparent;
    border-bottom: 3px solid transparent;
    &.active {
      background: transparent;
      color: $primary;
      border-bottom: 3px solid $primary;
    }
  }
  .nav-item {
    margin-bottom: -2px;
  }
}

// nav tabs dark
.nav-tabs.nav-tabs-dark {
  .nav-item .nav-link {
    color: $dark-gray;
  }
  .nav-link.active {
    background: $dark-gray;
    color: $white;
  }
}







//////////////////////////
/// Social


// Nav link active color
// .nav {
//   .nav-item{ 
//     .nav-link{
//       &.active{
//         color: $primary;
//       }
//     }
//   }
// }

// Pills
.nav-pills.nav-pills-light{
  .nav-link{
    &:hover{
      background-color: shade-color($light, 4%);
    }
    &.active{
      background-color: shade-color($light, 4%);
    }
  }
}

//Nav pills primary soft
.nav-pills-soft .nav-link.active,
.nav-pills-soft .show > .nav-link {
  background-color: rgba($primary, 0.1);
  color: $primary;
}



// tab line
.nav.nav-bottom-line{ 
  border-bottom: solid 1px $border-color;
  .nav-link{
    border: transparent;
    font-weight: 600;
    padding: ($nav-link-padding-y * 3) ($nav-link-padding-x * 1.3);
  }
  .nav-item:first-child .nav-link{
    padding-left: 0;
  }
  .nav-link{
    background: transparent;
    border-bottom: 3px solid transparent;
    &.active {
      background: transparent;
      color: $primary;
      border-bottom: 3px solid $primary;
    }
  }
  .nav-item{
    margin-bottom: -2px;
  }
}


// nav tabs white
.nav-tabs-white{
  border-radius: $border-radius;
  .nav-item{
    .nav-link{
      transition: $transition-base;
      border: 0;
      border-radius: $border-radius;
      margin-right: 4px;
      font-weight: 600;
    }
    &:last-child{
      .nav-link{
        margin-right: 0;
      }
    }
    .nav-link.active, .nav-link:hover{
      background: $mode;
      color: $primary;
    }
  }
}

// nav link secondary
.nav-link-secondary {
  .nav-item{ 
    .nav-link{
      color: $headings-color;
      &:hover, &.active{
        color: $primary;
      }
    }
  }
}

// nav stack 
.nav-stack{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 0.7rem;
  .nav-link{
    padding-left: 0;
    padding-right: 0;
  }
}



// nav icon
.nav{
  .nav-item{
    .nav-link{
      .nav-icon{
        background: $light;
        height: 2.1rem;
        width: 2.1rem;
        font-size: 1.1em;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: $transition-base;
      } 
      &:hover{
        .nav-icon{
          background: $primary;
          color: $white;
        }
      }
    } 
  }
}

// nav sidenav breakpoint up for nav sidenav
@include media-breakpoint-up(lg){
  // nav sidenav
  .nav-sidenav{
    width: 5rem;
    transition: width 0.3s;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    margin-top: $navbar-height;
    .nav-link{
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding-left: 0;
    }
    .nav-text{
      opacity: 0;
      visibility: hidden;
      transition: all 0.2s ease-in-out;
    }
  }
  
  // sidebar start enabled
  .sidebar-start-enabled{
    .nav-sidenav {
      width: 270px;
      .nav-text{
        display: flex;
        opacity: 1;
        visibility: visible;
        padding-left: 10px;
      }
    }
  }
}

// nav sidenav breakpoint down for nav sidenav
@include media-breakpoint-down(lg){
  // nav sidenav
  .nav-sidenav{
    width: 270px;
    left: 0;
    transition: transform .3s ease-in-out;
    position: fixed;
    top: 0;
    z-index: 9;
    margin-top: $navbar-height;
    transform: translateX(-100%);
    .nav-text{
      padding-left: 10px;
    }
  }
  .sidebar-start-enabled{
    .nav-sidenav{
      transform: none;
    }
  }
}


// Nav breakpoint up for sidebar end
@include media-breakpoint-up(lg){
  
  // sidebar end
  .sidebar-end{
    width: 5rem;
    transition: width 0.3s;
    position: fixed;
    right: 0;
    top: 0;
    margin-top: $navbar-height;
    z-index: 9;
    .sidebar-end-alignment{
      align-items: center;
    }
    .nav-link{
      display: flex;
      align-items: center;
    }
    .contact-name, .contact-search, .contact-title, .contact-status{
      opacity: 0;
      display: none;
      visibility: hidden;
      transition: all 0.5s ease-in-out;
      white-space: nowrap;
    }
  }
  
  // sidebar end enabled
  .sidebar-end-enabled{
    .sidebar-end{
      width: 270px;
      .sidebar-end-alignment{
        align-items: inherit;
      }
      .contact-name, .contact-search, .contact-title, .contact-status{
        display: flex;
        opacity: 1;
        visibility: visible;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

// sidebar end breakpoint down for sidebar end
@include media-breakpoint-down(lg){ 
  
  // sidebar-end
  .sidebar-end{
    width: 270px;
    right: 0;
    transition: transform .3s ease-in-out;
    position: fixed;
    top: 0;
    z-index: 9;
    transform: translateX(100%);
  }
  .sidebar-end-enabled{
    .sidebar-end{
      transform: none;
    }
  }
}


// nav divider
.nav.nav-divider{
  .nav-item+.nav-item:before{
    content: "\2022";
    color: inherit;
    padding-left: .6rem;
    padding-right: .6rem;
    opacity: 0.8;
  }
  .nav-item{
    display: flex;
    align-items: center;
  }
  .nav-link{
    padding: 0;
  }
}






//////////////////////////
/// folio

// tab bordered
.nav-tabs.nav-tabs-bordered {
  border: 1px solid $gray-200;
  .nav-link {
    border: transparent;
    border-radius: 0;
    &.active {
      background: $primary;
      color: $white;
      border-radius: 0;
    }
  }
}