// 
// pagination.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// For better responsive
.pagination {
  .page-item{
    margin-bottom: 5px;
    float: left;
  }
}

//Pagination primary soft
.pagination-primary-soft{
  border: none;
  padding: 4px;
  border-radius: $border-radius;
  .page-item{
    margin: 4px;
  }
  .page-link{
    border: transparent;
    border-radius: $border-radius !important;
    color:$primary;
    background-color: rgba($primary, 0.1);
  }
  
}
.pagination-primary-soft .page-link:hover {
  z-index: 2;
  color: $white;
  background-color: $primary;
  border-color: $primary;
}


///////////////////////////
/// Blogzine

// pagination bordered
.pagination-bordered{
  border: 1px solid $border-color;
  padding: 4px;
  border-radius: $border-radius;
  .page-item{
    margin: 2px;
  }
  .page-link{
    border: transparent;
    border-radius: $border-radius;
  }
}


// pagination bordered
.pagination-light{
  .page-item{
    margin: 2px;
  }
  .page-link{
    border-color: $light;
    background-color: $light;
    border-radius: $border-radius-sm;
    &:hover{
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }
  }
}


///////////////////////////
/// Folio

// pagination-line
.pagination.pagination-line {
  li.disabled{
    a{
      &:before {
        background: $pagination-disabled-color !important;
      }
    }
    // disabled Hover
    &:hover
      a{
        &:before {
          animation: none !important;
        }
      }
  }
  li{
    &:first-child{
      a{
        padding-left: 36px;
        &:before {
          content: "";
          width: 16px;
          height: 2px;
          display: block;
          background: $pagination-color;
          position: absolute;
          left: 15px;
          bottom: 15px;
          transition: $transition-base;
        }
      }
      // Hover
      &:hover
        a{
          &:before {
            background: $pagination-hover-color;
            animation: blink-animation 1s steps(5, start) infinite;
          }
        }
    }
    &:last-child{
      a{
        padding-right: 36px;
        &:after {
          content: "";
          width: 16px;
          height: 2px;
          display: block;
          background: $pagination-color;
          position: absolute;
          right: 15px;
          bottom: 15px;
          transition: $transition-base;
        }
      }
      // Hover
      &:hover
        a{
          &:after {
            background: $pagination-hover-color;
            animation: blink-animation 1s steps(5, start) infinite;
          }
        }
    }
  }
}
