// 
// dropdowns.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides
//

//Dropdown soft hover
.dropdown-item.active, .dropdown-item:active,
.dropdown-item:hover, .dropdown-item:focus{
  border-radius: $border-radius;
}
.dropdown-menu{
  padding-left: 10px;
  padding-right: 10px;
  z-index: 1100;
  box-shadow: $box-shadow;
}

// Dropdown menu size
.dropdown-menu-size-sm{
  min-width: 13rem;
}
.dropdown-menu-size-md{
  min-width: 22rem;
}
.dropdown-menu-size-lg{
  min-width: 30rem;
}

@include media-breakpoint-down(sm) {
  .dropdown-menu-size-md{
    min-width: 16rem;
    margin-right: -25px !important;
  }
  .dropdown-menu-size-sm{
    min-width: 13rem;
  }
}

//Dropdown custom icon
.dropdown .dropdown-toggle:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

//Dropup custom icon
.dropup .dropdown-toggle:after {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

//Dropstart custom icon
.dropstart .dropdown-toggle:before {
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

//Dropend custom icon
.dropend .dropdown-toggle:after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

// Dropdown custom size
@include media-breakpoint-down(lg) {
  .dropdown-width-lg{
    width: 100%;
    padding: 0;
  }
}

@include media-breakpoint-up(xl) {
  .dropdown-width-lg{
    width: 40rem;
    padding: 0;
  }
}

@include media-breakpoint-up(xxl) {
  .dropdown-width-lg{
    width: 50rem;
    padding: 0;
  }
}

// Dropdown mega menu
.dropdown-fullwidth {
  .dropdown-menu {
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 1rem 0;
  }
}
@include media-breakpoint-up(xl){
  .dropdown-fullwidth {
    position: static;
  }
}

// Dropdown fullwidth menu width on responsive
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint) {
    .dropdown-fullwidth {
      .dropdown-menu {
        max-width: $container-max-width; 
      }
    }
  }
}

// dropdown menu start end position
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .dropdown-menu#{$infix}-start {
      left: 0;
    }
    .dropdown-menu#{$infix}-end {
      right: 0;
    }
  }
}

@include media-breakpoint-down(xl) {
  .dropdown-fullwidth .dropdown-menu .container{
    max-width: 100%;
  }
}

// Dropdown menu slide in animation on click
.dropdown-menu.dropdown-animation.show {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: dropdownslideIn;
  position: absolute !important;
  top: 100% !important;
}

@keyframes dropdownslideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform:translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes dropdownslideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}









//////////////////////////////////
/// Blogzine

.dropdown-item {
  font-weight: $dropdown-item-font-weight;
  text-transform: capitalize;
  position: relative;
}

// .dropdown-item.active {
//   color: $primary;
//   &:before {
//     content: "";
//     width: 7px;
//     height: 7px;
//     background:$primary;
//     display: inline-block;
//     border-radius: 50%;
//     margin-left: -12px;
//     margin-right: 5px;
//     margin-bottom: 1px;
//   }
// }


// Dropdown menu open on hover after xl
@include media-breakpoint-up(xl) {
  .navbar-expand-xl {
    .navbar-nav {
      .dropdown-menu {
        // top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-xl .dropdown-submenu {
    position: relative;
  }
}

// Dropdown menu open on hover after lg
@include media-breakpoint-up(lg) {
  .navbar-expand-lg {
    .navbar-nav {
      .dropdown-menu {
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            // top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-lg .dropdown-submenu {
    position: relative;
  }
}


// Dropdown menu open on hover after md
@include media-breakpoint-up(md) {
  .navbar-expand-md {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-md .dropdown-submenu {
    position: relative;
  }
}

// Dropdown menu open on hover after sm
@include media-breakpoint-up(sm) {
  .navbar-expand-sm {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: all 0.1s ease-in-out;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
            transition: $transition-base;
          }
        }
      }
    }
  }
  .navbar-expand-sm .dropdown-submenu {
    position: relative;
  }
}

// dropdown toggle icon none
.dropdown-toggle-icon-none{
  .dropdown-toggle:after{
    content: none !important;
  }
}










///////////////////
/// Social 


// Dropdown overrides for navbar only
.dropdown-menu .dropdown-toggle {
  &:after {
    position: absolute;
    right: 25px;
    top: 10px
  }
  &:before {
    position: absolute;
    right: 25px;
    top: 10px
  }
}




//////////////////////////////////
/// Folio

.dropdown-header {
  font-weight: $dropdown-header-font-weight;
  padding-top: $dropdown-padding-y - .5rem;
  font-size: $dropdown-header-font-size;
}

// dropdown toggle for offcanvas
.dropdown-toggle-start-icon {
  .dropdown-toggle {
    position: absolute;
    top: 20px;
    left: -40px;
    background: rgba($primary, 0.2);
    border: none !important;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    &:after{
      border: none !important;
    }
  }
  .collapsed.dropdown-toggle:after {
    content: "\f00d";
    color: $primary;
    font-weight: bold;
    font-family: 'Font Awesome 6 Free';
    font-size: 14px;
    top: 5px;
    transform: rotate(45deg);
    border-top: none;
    transition: $transition-base;
  }
  .dropdown-toggle:after {
    content: "\f00d";
    color: $primary;
    font-weight: bold;
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    top: 5px;
    transform: rotate(0deg);
    transition: $transition-base;
  }
}