// 
// tables.scss
// Extended from Bootstrap
// 

// 
// Additional styles for theme
// 

.table.table-dark-gray {
  thead{
  background-color: $dark-gray !important;
  color:$white;
  }
  tbody{
    border-top: none;
  }
}

.table td, .table th {
  white-space: nowrap;
}

@media (min-width: 767.98px) {
  .table-responsive .table td, .table-responsive .table th {
    white-space: inherit;
  }
}
@media (max-width: 767.98px) {
  .table-responsive .table-responsive-title{
    width: 200px;
    white-space: normal;
  }
}

.table-border-color{
  border-color: $gray-300;
}


///////////////////////
/// BLogzine

// Table shrink for better responsive
@include media-breakpoint-up(md) {
  .table-shrink.table td, .table-shrink.table th {
    white-space: inherit;
  }
}

@include media-breakpoint-down(md) {
  .table-shrink .table-title{
    width: 200px;
    white-space: normal;
  }
}